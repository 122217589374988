
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Series } from '~/models/series'
import { DateFormat, SeriesDisplay } from '~/assets/ts/enums'
import { siteBroadcasterUrl, siteSeriesUrl } from '~/assets/ts/utils/urls'
import SeriesImage from '~/components/_general/SeriesImage.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaLink from '~/components/_general/SaLink.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import VerticalAlign from '~/components/_general/VerticalAlign.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import FollowButton from '~/components/_general/FollowButton.vue'
import CoreElementWrapper from '~/components/_general/CoreElementWrapper.vue'
import { localizeDateTime } from '~/assets/ts/utils/date'
import { convertLineBreaks, truncateString } from '~/assets/ts/utils/strings'

export default Vue.extend({
  name: 'SeriesElement',
  components: {
    CoreElementWrapper,
    FollowButton,
    MarkdownElement,
    VerticalAlign,
    SaIcon,
    SaLink,
    LoadingElement,
    SeriesImage,
  },
  props: {
    series: {
      type: Object as PropType<Series>,
      default: undefined,
    },
    maxAlbumWidth: {
      type: Number,
      default: 256,
    },
    display: {
      type: Number,
      default: SeriesDisplay.Standard,
    },
    randomBackground: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
    },
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    includeFollowButton: {
      type: Boolean,
    },
    matchAlbumMax: {
      type: Boolean,
      default: true,
    },
    includeUpdatedDate: {
      type: Boolean,
    },
    includeBroadcaster: {
      type: Boolean,
      default: true,
    },
    removeDescriptionLineBreaks: {
      type: Boolean,
    },
    listDivider: {
      type: Boolean,
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    updatedDateString(): TranslateResult {
      if (!this.series) return ''
      return this.$t('Last Updated: {date}', {
        date: localizeDateTime(this.series.updated, DateFormat.CompressedDate),
      })
    },
    showInlineButtons(): boolean {
      return (
        this.includeFollowButton &&
        !this.small &&
        !this.album &&
        !this.infoColumn
      )
    },
    featured(): boolean {
      return this.display === SeriesDisplay.Featured
    },
    extended(): boolean {
      return this.display === SeriesDisplay.Extended
    },
    infoColumn(): boolean {
      return this.display === SeriesDisplay.Info
    },
    album(): boolean {
      return (
        this.display === SeriesDisplay.Album ||
        this.display === SeriesDisplay.AlbumWrap
      )
    },
    wrap(): boolean {
      return (
        this.display === SeriesDisplay.Featured ||
        this.display === SeriesDisplay.Info ||
        this.display === SeriesDisplay.AlbumWrap
      )
    },
    seriesUrl(): string {
      return siteSeriesUrl(this.series)
    },
    broadcasterUrl(): string {
      return siteBroadcasterUrl(this.series?.broadcaster)
    },
    broadcasterID(): string {
      return this.series ? this.series.broadcasterID : ''
    },
    seriesID(): string {
      return this.series ? this.series.id.toString() : ''
    },
    seriesTitle(): string {
      return this.series ? this.series.shareTitle : ''
    },
    sermonCount(): string {
      if (!this.series) return ''
      return this.$tc('{n} Sermon | {n} Sermons', this.series.count, {
        n: this.series.count.toLocaleString(),
      })
    },
    imageSize(): number {
      if (this.album) return this.maxAlbumWidth
      if (!this.small && this.featured) {
        return 256
      } else {
        return 128
      }
    },
    imageWidth(): string {
      if (!this.small && this.featured) {
        return 'w-12 xs:w-20 sm:w-32'
      } else if ((!this.small && this.extended) || this.infoColumn) {
        return 'w-16'
      } else {
        return 'w-12'
      }
    },
    description(): string | undefined {
      if (!this.series || !this.series.description) {
        return undefined
      }

      return truncateString(
        this.removeDescriptionLineBreaks
          ? convertLineBreaks(this.series.description, ' ')
          : this.series.description,
        400
      )
    },
  },
  mounted() {
    this.mounted = true
  },
})
